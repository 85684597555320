
import {
    defineAsyncComponent, defineComponent, ref, watch, computed
} from 'vue';
import { TabsData } from '@/views/pm/device/types/all.type';
import router, { installerBaseRouter, installerSubRouter } from '@/router';
import { isCNServer } from '@/util/location';

export default defineComponent({
    props: {
        // 设备类型 akuvox设备 和 第三方设备
        type: {
            type: String,
            default: 'intercom'
        },
        // 第三方设备三级菜单标识
        deviceType: {
            type: String,
            default: isCNServer() ? 'control' : 'camera'
        }
    },
    setup(props) {
        // 控制akuvox设备和第三方设备展示的数据
        const tabsButtonName = computed(() => props.type);
        const tabsButtonData: TabsData = {
            intercom: {
                label: WordList.IntercomDevices,
                name: 'intercom',
                component: defineAsyncComponent(() => import('@/views/installer/device/components/intercom-device.vue'))
            },
            thirdPartyDevices: {
                label: WordList.ThirdPartyDevices,
                name: 'thirdPartyDevices',
                component: ''
            }
        };

        // 控制第三方设备展示标签页数据
        const tabsActiveName = ref(props.deviceType);
        const baseTabs = {
            control: {
                label: WordList.ControlPanel,
                name: 'control',
                component: defineAsyncComponent(() => import('@/views/installer/device/components/control-devices.vue'))
            },
            camera: {
                label: WordList.Camera,
                name: 'camera',
                component: defineAsyncComponent(() => import('@/views/installer/device/components/camera-devices.vue'))
            }
        };
        let tabsData: TabsData = { camera: baseTabs.camera };
        if (isCNServer()) {
            tabsData = baseTabs;
        }

        const changeTabsButton = (key: string) => {
            router.push(`/${installerBaseRouter}/${installerSubRouter.device}?type=${key}`);
        };

        const changeTab = (name: any) => {
            router.push(`/${installerBaseRouter}/${installerSubRouter.device}?type=${props.type}&deviceType=${name.paneName}`);
        };

        return {
            tabsButtonName,
            tabsButtonData,
            tabsActiveName,
            tabsData,
            changeTabsButton,
            changeTab
        };
    }
});
